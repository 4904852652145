import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PageNotFound from "./pages/PageNotFound";
import MasterDashboard from "./pages/Dashbaord/MasterDashboard";
import MasterProfile from "./pages/masterPages/profilePages/MasterProfile";
import CreateEmpId from "./pages/masterPages/administrationPages/CreateEmpId.js";
import ViewEmpList from "./pages/masterPages/administrationPages/employeeAccessPages/ViewEmpList.js";
import ClientAdmin from "./pages/masterPages/profilePages/ClientAdmin";
import ClientSuperAdmin from "./pages/masterPages/profilePages/ClientSuperAdmin";
import MasterServices from "./pages/masterPages/servicesPages/MasterServices";
import Administration from "./pages/masterPages/administrationPages/Administration.js";
import MasterLogin from "./pages/auth/MasterLogin.js";
import MembershipServices from "./pages/masterPages/servicesPages/MembershipServices.js";
import RecommendationLetter from "./pages/masterPages/servicesPages/RecommendationLetter.js";
import PersonalDetails from "./pages/masterPages/profilePages/PersonalDetails.js";
import CompanyDetails from "./pages/masterPages/profilePages/CompanyDetails.js";
import EmployeeAccessAdmin from "./pages/masterPages/administrationPages/employeeAccessPages/EmployeeAccessAdmin.js";
import EmployeeMasterProfile from "./pages/masterPages/administrationPages/employeeAccessPages/EmployeeMasterProfile.js";
import EmployeeAccessServices from "./pages/masterPages/administrationPages/employeeAccessPages/EmployeeAccessServices.js";
import EmployeeAccessWings from "./pages/masterPages/administrationPages/employeeAccessPages/EmployeeAccessWings.js";
import EmployeeAccessArbitrationCenter from "./pages/masterPages/administrationPages/employeeAccessPages/EmployeeAccessArbitrationCenter.js";
import ChangePassword from "./pages/masterPages/profilePages/ChangePassword.js";
import MarketingInfo from "./pages/masterPages/profilePages/MarketingInfo.js";
import CollabrationPortal from "./pages/masterPages/servicesPages/CollabrationPortal.js";
import Payment from "./pages/masterPages/servicesPages/Payment";
import ECOAdmin from "./pages/masterPages/servicesPages/eCoAdmin";
import ECOSuperadmin from "./pages/masterPages/servicesPages/eCoSuperadmin";
import CommertialDir from "./pages/masterPages/servicesPages/CommertialDir";
import PrivateRoute from "./components/routes/PrivateRoutes";
import EcoVerification from "./pages/masterPages/servicesPages/eCoResources";
import Verification from "./pages/masterPages/servicesPages/Verification";
import EcoResources from "./pages/masterPages/servicesPages/eCoResources";
import EmpDashboard from "./pages/Dashbaord/EmpDashboard";
import Publication from "./pages/masterPages/publicationPages/Publication";

import ClientVerification from "./pages/masterPages/servicesPages/Verification";
import ProtectedRoute from "./components/routes/ProtectedRoutes";
import HrDepartment from "./pages/masterPages/hrDepartPages/MasterHrDepartment";
import HrDailyReport from "./pages/masterPages/hrDepartPages/HrDailyReport";
import MasterWings from "./pages/masterPages/wingsPages/MasterWings";
import MasterArbitration from "./pages/masterPages/arbitrationPages/MasterArbitration";
import MasterNewsAlert from "./pages/masterPages/newsPages/MasterNewsAlert";
import MasterPublication from "./pages/masterPages/publicationPages/MasterPublication";
import MasterHrDepartment from "./pages/masterPages/hrDepartPages/MasterHrDepartment";
import SpecialControls from "./pages/masterPages/specialControlPages/SpecialControl";
import SecuredRoute from "./components/routes/SecuredRoutes";
import NonStandDoc from "./pages/masterPages/servicesPages/NonStandDoc";
import ExtraAccessControl from "./pages/masterPages/specialControlPages/ExtraAccessControl";
import PriceControl from "./pages/masterPages/specialControlPages/PriceControl";
import MasterReport from "./pages/masterPages/reportAndAnalysisPages/MasterReport";
import DumpFiles from "./pages/masterPages/specialControlPages/DumpFiles";
import ValidityAccess from "./pages/masterPages/specialControlPages/ValidityAccess";
import MasterEvent from "./pages/masterPages/eventPages/MasterEvent";
import Event from "./pages/masterPages/eventPages/Event";
import EmployeeAccessEvents from "./pages/masterPages/administrationPages/employeeAccessPages/EmployeeAccessEvents";
import EmployeeAccessPublication from "./pages/masterPages/administrationPages/employeeAccessPages/EmployeeAccessPublication";
import EmployeeAccessSpecialControl from "./pages/masterPages/administrationPages/employeeAccessPages/EmployeeAccessSpecialControl";
import MarketingDemo from "./pages/masterPages/profilePages/MarketingDemo";
import MasterReportAnalysis from "./pages/masterPages/reportAndAnalysisPages/MasterReportAnalysis";
import MasterAnalysis from "./pages/masterPages/reportAndAnalysisPages/MasterAnalysis";
import ReportsDSR from "./pages/masterPages/reportAndAnalysisPages/ReportsDSR";
import AdBooking from "./pages/masterPages/publicationPages/adBooking";
import ExportWing from "./pages/masterPages/wingsPages/ExportWing";
import LegalWing from "./pages/masterPages/wingsPages/LegalWing";
import EventWing from "./pages/masterPages/wingsPages/EventWing";
import HrWing from "./pages/masterPages/wingsPages/HrWing";
import BusinessWing from "./pages/masterPages/wingsPages/BusinessWing";
import ProfessionalWing from "./pages/masterPages/wingsPages/ProfessionalWing";
import EmployeeAccessReportAnalysis from "./pages/masterPages/administrationPages/employeeAccessPages/EmployeeAccessReportAnalysis";
import EmployeeAccessHrDepartment from "./pages/masterPages/administrationPages/employeeAccessPages/EmployeeAccessHrDepartment";
import RaiseYourDispute from "./pages/masterPages/arbitrationPages/RaiseYourDispute";
import HrJdList from "./pages/masterPages/hrDepartPages/HrJdList";
import EmpJdPage from "./pages/masterPages/hrDepartPages/EmpJdPage";
import MarketingInterest from "./pages/masterPages/profilePages/MarketingInterest";
import { B2BEvents } from "./pages/masterPages/profilePages/B2BEvents";
import LegalWingRequest from "./pages/masterPages/profilePages/LegalWingRequest";
import B2bBookings from "./pages/masterPages/profilePages/B2bBookings";
import B2bCountrySlotControl from "./pages/masterPages/profilePages/B2bCountrySlotControl";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/master-dashboard"
          element={
            <SecuredRoute Component={MasterDashboard} allowedRole="master" />
          }
        />{" "}
        <Route
          path="/employee-dashboard"
          element={<PrivateRoute Component={EmpDashboard} />}
        />{" "}
        {/* routes for master authentication ===================
        ===============================================
        ================================================*/}
        <Route path="/login" element={<MasterLogin />} />
        {/* routes for master adinistration ===================
        ===============================================
        ================================================*/}
        <Route
          path="/master-administration"
          element={<PrivateRoute Component={Administration} />}
        />
        <Route
          path="/master-profile-create-emp"
          element={
            <ProtectedRoute
              Component={CreateEmpId}
              componentName="CreateEmpId"
            />
          }
        />
        <Route
          path="/master-profile-view-emp"
          element={
            <ProtectedRoute
              Component={ViewEmpList}
              componentName="ViewEmpList"
            />
          }
        />
        <Route
          path="/master-employee-access-admin"
          element={
            <ProtectedRoute
              Component={EmployeeAccessAdmin}
              componentName="EmployeeAccessAdmin"
            />
          }
        />
        {/* routes for master profile pages =========
        ============================================
        ==============================================*/}
        <Route
          path="/master-profile-employee"
          element={<PrivateRoute Component={EmployeeMasterProfile} />}
        />
        <Route
          path="/master-profile"
          element={<PrivateRoute Component={MasterProfile} />}
        />
        <Route
          path="/master-profile-client-admin"
          element={
            <ProtectedRoute
              Component={ClientAdmin}
              componentName="ClientAdmin"
            />
          }
        />
        {/* <Route
          path="/master-marketing-demo-request"
          element={
            <ProtectedRoute
              Component={MarketingDemo}
              componentName="MarketingDemo"
            />
          }
        /> */}
        <Route
          path="/master-marketing-demo-request"
          element={<PrivateRoute Component={MarketingDemo} />}
        />
        <Route
          path="/master-b2b-events"
          element={<PrivateRoute Component={B2BEvents} />}
        />
        <Route
          path="/master-b2b-bookings"
          element={<PrivateRoute Component={B2bBookings} />}
        />
        <Route
          path="/master-country-slot-control"
          element={<PrivateRoute Component={B2bCountrySlotControl} />}
        />
        <Route
          path="/master-marketing-interest"
          element={<PrivateRoute Component={MarketingInterest} />}
        />
        <Route
          path="/master-legalwing-interest"
          element={<PrivateRoute Component={LegalWingRequest} />}
        />
        <Route
          path="/master-profile-super-admin"
          element={
            <ProtectedRoute
              Component={ClientSuperAdmin}
              componentName="ClientSuperAdmin"
            />
          }
        />
        <Route
          path="/master-profile-personal-details"
          element={
            <ProtectedRoute
              Component={PersonalDetails}
              componentName="PersonalDetails"
            />
          }
        />
        <Route
          path="/master-profile-company-details"
          element={
            <ProtectedRoute
              Component={CompanyDetails}
              componentName="CompanyDetails"
            />
          }
        />
        <Route
          path="/master-profile-marketing-info"
          element={
            <ProtectedRoute
              Component={MarketingInfo}
              componentName="MarketingInfo"
            />
          }
        />
        <Route
          path="/master-profile-change-password"
          element={
            <ProtectedRoute
              Component={ChangePassword}
              componentName="ChangePassword"
            />
          }
        />
        {/*========== New Pages ==========*/}
        <Route
          path="/employee-access-services"
          element={
            <ProtectedRoute
              Component={EmployeeAccessServices}
              componentName="EmployeeAccessServices"
            />
          }
        />
        <Route
          path="/employee-access-wings"
          element={<PrivateRoute Component={EmployeeAccessWings} />}
        />
        <Route
          path="/employee-access-arbitration-center"
          element={<PrivateRoute Component={EmployeeAccessArbitrationCenter} />}
        />
        <Route
          path="/employee-access-events"
          element={<PrivateRoute Component={EmployeeAccessEvents} />}
        />
        <Route
          path="/employee-access-publication"
          element={<PrivateRoute Component={EmployeeAccessPublication} />}
        />
        <Route
          path="/employee-access-special-control"
          element={<PrivateRoute Component={EmployeeAccessSpecialControl} />}
        />
        <Route
          path="/employee-access-report-analysis"
          element={<PrivateRoute Component={EmployeeAccessReportAnalysis} />}
        />
        <Route
          path="/employee-access-hrdepartment"
          element={<PrivateRoute Component={EmployeeAccessHrDepartment} />}
        />
        {/* routes for master services pages =========
        ============================================
        ==============================================*/}
        <Route
          path="/master-services"
          element={<PrivateRoute Component={MasterServices} />}
        />
        <Route
          path="/master-semi-eco"
          element={
            <ProtectedRoute Component={ECOAdmin} componentName="ECOAdmin" />
          }
        />
        <Route
          path="/master-electronic-eco"
          element={
            <ProtectedRoute
              Component={ECOSuperadmin}
              componentName="ECOSuperadmin"
            />
          }
        />
        <Route
          path="/master-non-standard"
          element={<PrivateRoute Component={NonStandDoc} />}
        />
        <Route
          path="/master-eco-resources"
          element={
            <ProtectedRoute
              Component={EcoResources}
              componentName="EcoResources"
            />
          }
        />
        <Route
          path="/master-verification"
          element={
            <ProtectedRoute
              Component={ClientVerification}
              componentName="ClientVerification"
            />
          }
        />
        <Route
          path="/master-membership-services"
          element={
            <ProtectedRoute
              Component={MembershipServices}
              componentName="MembershipServices"
            />
          }
        />
        <Route
          path="/master-recomendation-letter"
          element={
            <ProtectedRoute
              Component={RecommendationLetter}
              componentName="RecommendationLetter"
            />
          }
        />
        <Route
          path="/master-services-commertial-dir"
          element={
            <ProtectedRoute
              Component={CommertialDir}
              componentName="CommertialDir"
            />
          }
        />
        <Route
          path="/master-services-collab-portal"
          element={
            <ProtectedRoute
              Component={CollabrationPortal}
              componentName="CollabrationPortal"
            />
          }
        />
        <Route
          path="/master-services-payment"
          element={
            <ProtectedRoute Component={Payment} componentName="Payment" />
          }
        />
        {/* <Route
          path="/master-profile-payment"
          element={<ProtectedRoute Component={Payment} />}
        /> */}
        {/* routes for HR DEPARTMENT pages====================
        ======================================================
        ====================================================== */}
        <Route
          path="/master-hr-department"
          element={<PrivateRoute Component={MasterHrDepartment} />}
        />
        <Route
          path="/master-hr-daily-report"
          element={<PrivateRoute Component={HrDailyReport} />}
        />
        <Route
          path="/master-hr-jd-list"
          element={<PrivateRoute Component={HrJdList} />}
        />
        <Route path="/master-hr-emp-jd" element={<EmpJdPage />} />
        {/* routes for PUBLICATION pages====================
        ======================================================
        ====================================================== */}
        <Route
          path="/master-publication-ads"
          element={<PrivateRoute Component={MasterPublication} />}
        />
        <Route
          path="/ad-booking"
          element={<PrivateRoute Component={AdBooking} />}
        />
        <Route
          path="/publication"
          element={<PrivateRoute Component={Publication} />}
        />
        {/* routes for master Services pages====================
=======
        {/* routes for TTHE WINGS pages====================
        ======================================================
        ====================================================== */}
        <Route
          path="/master-wings"
          element={<PrivateRoute Component={MasterWings} />}
        />
        <Route
          path="/export-wing"
          element={<PrivateRoute Component={ExportWing} />}
        />
        <Route
          path="/legal-wing"
          element={<PrivateRoute Component={LegalWing} />}
        />
        <Route path="/Hr-wing" element={<PrivateRoute Component={HrWing} />} />
        <Route
          path="/Business-wing"
          element={<PrivateRoute Component={BusinessWing} />}
        />
        <Route
          path="/Professional-wing"
          element={<PrivateRoute Component={ProfessionalWing} />}
        />
        <Route
          path="/event-and-seminar-wing"
          element={<PrivateRoute Component={EventWing} />}
        />
        {/* routes for ARBITRATION CENTRE pages====================
        ======================================================
        ====================================================== */}
        <Route
          path="/master-arbitration"
          element={<PrivateRoute Component={MasterArbitration} />}
        />
        <Route
          path="/raise-your-dispute"
          element={<PrivateRoute Component={RaiseYourDispute} />}
        />
        {/* routes for NEWS ALERT pages====================
        ======================================================
        ====================================================== */}
        <Route
          path="/master-news"
          element={<PrivateRoute Component={MasterNewsAlert} />}
        />
        {/* routes for Event pages====================
        ======================================================
        ====================================================== */}
        <Route
          path="/master-events"
          element={<PrivateRoute Component={MasterEvent} />}
        />
        <Route path="/event" element={<PrivateRoute Component={Event} />} />
        {/* routes for PUBLICATION pages====================
        ======================================================
        ====================================================== */}
        <Route
          path="/master-publication"
          element={<PrivateRoute Component={MasterPublication} />}
        />
        {/* routes for Dump page====================
        ======================================================
        ====================================================== */}
        <Route
          path="/special-controls"
          element={<PrivateRoute Component={SpecialControls} />}
        />
        <Route
          path="/extra-access-controls"
          element={<PrivateRoute Component={ExtraAccessControl} />}
        />
        <Route
          path="/master-price-control"
          element={<PrivateRoute Component={PriceControl} />}
        />
        <Route
          path="/master-validity-access"
          element={<PrivateRoute Component={ValidityAccess} />}
        />
        <Route
          path="/master-dump-files"
          element={<PrivateRoute Component={DumpFiles} />}
        />
        {/* routes for REPORT AND ANALYSIS====================
        ======================================================
        ====================================================== */}
        <Route
          path="/master-report-analysis"
          element={<PrivateRoute Component={MasterReportAnalysis} />}
        />
        <Route
          path="/master-reports"
          element={<PrivateRoute Component={MasterReport} />}
        />
        <Route
          path="/master-analysis"
          element={<PrivateRoute Component={MasterAnalysis} />}
        />
        <Route
          path="/master-reports-dsr"
          element={<PrivateRoute Component={ReportsDSR} />}
        />
        {/* routes for general pages====================
        ======================================================
        ====================================================== */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
