import React, { useState } from "react";
import { Modal, Button, CloseButton, Form } from "react-bootstrap";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillCheckSquare,
  AiFillCloseSquare,
} from "react-icons/ai";
import { FaCircleExclamation } from "react-icons/fa6";

const FileViewModal = ({ show, onHide, title, fileLink }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <iframe
            src={fileLink}
            title={title}
            width="100%"
            height="700px"
            frameBorder="0"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileViewModal;

export function PopUpModalButton({ title, fileLink, variant }) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button variant={variant}
        className=" text-nowrap px-2 py-1 text-white rounded small-font"
        onClick={() => setModalShow(true)}
      >
        {title}
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        centered
      >
        <Modal.Header
          style={{ backgroundColor: "#0b3b5d", color: "white" }}
          className="text-white"
        >
          <Modal.Title>{title}</Modal.Title>

          <CloseButton variant="white" onClick={() => setModalShow(false)} />
        </Modal.Header>{" "}
        <Modal.Body>
          <div>
            <iframe
              src={fileLink}
              title={title}
              width="100%"
              height="700px"
              frameBorder="0"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

//are you sure popup modal

export function ConfirmModal({ handleApproveRejectEco, userId }) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="m-0 p-0 border-none"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <AiFillCheckCircle
          className="w-100 icon-size green-color"
          onClick={() => setModalShow(true)}
        />
      </Button>
      <Button
        className="m-0 p-0 border-none"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <AiFillCloseCircle
          className="w-100 icon-size text-danger"
          onClick={() => setModalShow(true)}
          // onClick={() => handleApproveRejectEco(data._id, false)}
        />
      </Button>

      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <div className="text-end">
          <CloseButton
            onClick={() => setModalShow(false)}
            className="m-3 mb-0 pb-0"
          />
        </div>

        <Modal.Body className="pt-0">
          <div className="text-center">
            <FaCircleExclamation
              style={{ color: "#0b3b5d", fontSize: "8rem" }}
            />

            <h4 className="text-info mb-4 mt-2">
              Are you sure you want to proceed?
            </h4>

            <div className="d-flex justify-content-center gap-3">
              <Button
                variant="success"
                className="px-5"
                onClick={() => handleApproveRejectEco(userId, true)}
              >
                Accept
              </Button>
              <Button
                variant="danger"
                className="px-5"
                onClick={() => handleApproveRejectEco(userId, false)}
              >
                Reject
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}


export function PaymentConfirmModal({ handleUpdateRejectAddFund, clientId, userId }) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="m-0 p-0 border-none"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <AiFillCheckCircle
          className="w-100 icon-size green-color"
          onClick={() => setModalShow(true)}
        />
      </Button>
      <Button
        className="m-0 p-0 border-none"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <AiFillCloseCircle
          className="w-100 icon-size text-danger"
          onClick={() => setModalShow(true)}
          // onClick={() => handleApproveRejectEco(data._id, false)}
        />
      </Button>

      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <div className="text-end">
          <CloseButton
            onClick={() => setModalShow(false)}
            className="m-3 mb-0 pb-0"
          />
        </div>

        <Modal.Body className="pt-0">
          <div className="text-center">
            <FaCircleExclamation
              style={{ color: "#0b3b5d", fontSize: "8rem" }}
            />

            <h4 className="text-info mb-4 mt-2">
              Are you sure you want to proceed?
            </h4>

            <div className="d-flex justify-content-center gap-3">
              <Button
                variant="success"
                className="px-5"
                onClick={() => handleUpdateRejectAddFund(clientId, userId, true)}
              >
                Accept
              </Button>
              <Button
                variant="danger"
                className="px-5"
                onClick={() => handleUpdateRejectAddFund(clientId, userId, false)}
              >
                Reject
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}


//simple modla as per body
export function PopUpModalPriceRecord({ title, updateListData }) {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      {/* <FcAnswers
        className="ms-2 icon-size cursor-pointer"
        onClick={() => setModalShow(true)}
      /> */}
      <h6 className="cursor-pointer" onClick={() => setModalShow(true)}>
        {title}
      </h6>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        centered
      >
        <Modal.Body>
          <h4 className="mb-4">Date And Time : {title}</h4>
          <div className="d-flex justify-content-between">
            <div>
              <h5>Eco Pricing</h5>
              <h6 className="no-wrap">Eco Price: {updateListData.eco}</h6>
              <h6 className="no-wrap">
                Commercial Invoice: {updateListData.comInvoiceEco}
              </h6>
              <h6 className="no-wrap">
                Packing List: {updateListData.packListEco}
              </h6>
              <h6 className="no-wrap">
                Additional Document: {updateListData.addDocEco}
              </h6>
              <h6 className="no-wrap">
                Processing Charges: {updateListData.ecoPc}
              </h6>
            </div>
            <div>
              <h5>Semi-Eco Pricing</h5>
              <h6 className="no-wrap">Eco Price: {updateListData.semiEco}</h6>
              <h6 className="no-wrap">
                Commercial Invoice: {updateListData.comInvoiceMco}
              </h6>
              <h6 className="no-wrap">
                Packing List: {updateListData.packListMco}
              </h6>
              <h6 className="no-wrap">
                Additional Document: {updateListData.addDocMco}
              </h6>
              <h6 className="no-wrap">
                Intercity/Gujrat: {updateListData.interGujDelMode}
              </h6>
              <h6 className="no-wrap">
                Pan India: {updateListData.panIndDelMode}
              </h6>
              <h6 className="no-wrap">Semi-ECO(PC): {updateListData.mcoPc}</h6>
              <h6 className="no-wrap">
                Commercial Invoice (PC): {updateListData.mcoComInvoicePc}
              </h6>{" "}
              <h6 className="no-wrap">
                Packing List (PC): {updateListData.mcoPackListPc}
              </h6>{" "}
              <h6 className="no-wrap">
                Additional Document (PC): {updateListData.mcoAddDocPc}
              </h6>
            </div>
            <div>
              <h5>Non-Standard Pricing</h5>
              <h6 className="no-wrap">
                A. Commercial Document: {updateListData.A_ComDoc}
              </h6>
              <h6 className="no-wrap">
                B. MCA Document: {updateListData.B_McaDoc}
              </h6>
              <h6 className="no-wrap">
                C. Export Permission related Document: {updateListData.C_ExpDoc}
              </h6>
              <h6 className="no-wrap">
                D. Pharma Reg. Documents: {updateListData.D_PharmDoc}
              </h6>
              <h6 className="no-wrap">
                E. Agro Chemical: {updateListData.E_AgroDoc}
              </h6>{" "}
              <h6 className="no-wrap">
                F. Other document: {updateListData.F_OtherDoc}
              </h6>{" "}
              <h6 className="no-wrap">
                Processing Charges: {updateListData.nonStandPc}
              </h6>
            </div>
          </div>
          <CloseButton
            variant="danger"
            className="position-absolute top-0 end-0 mt-4 me-4"
            onClick={() => setModalShow(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
