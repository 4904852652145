import axios from "axios";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import { Alert, Badge, Button, Card, CloseButton, Col, Form, Modal, OverlayTrigger, Pagination, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import { FaCalendarAlt, FaCheckCircle, FaClock, FaMoneyBill } from "react-icons/fa";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { ConfirmModal } from "../../../components/basic/fileViewModal";
import { formatDateTimeStandard, numberToWords } from "../../../components/basic/simpleFunctions";
import { getURLbyEndPointV2 } from "../../../store/api";
import { getAuthUserCookie } from "../../../store/services";

const B2bBookings = () => {
  const [b2bData, setB2bData] = useState([]);
  const [reqStatus, setReqStatus] = useState("Processing");
  const [searchTerm, setSearchTerm] = useState("");


  const fetchB2bData = async () => {
    try {
      const response = await axios.get(getURLbyEndPointV2("getAllB2bRequest"));
      if (response.status === 200) {
        const data = response.data.data;

        const modifiedData = data.map((item) => {
          if (item.isPaymentApprove === "Processing") {
            return {
              ...item,
            };
          } else if (item.isPaymentApprove === "Approved") {
            return {
              ...item,
            };
          }
          return item;
        });

        setB2bData([...modifiedData]);
        // console.log("result", modifiedData);
      } else {
        alert("Failed to fetch co data.");
      }
    } catch (error) {
      alert("Error fetching data:", error);
    }
  };


  //filtering data
  const filteredData = b2bData.filter((data) => {
    // Filter based on account status
    if (reqStatus !== "" && data.isPaymentApprove !== reqStatus) {
      return false;
    }

    // Filter based on appNo, forComRegion, or email
    if (
      searchTerm !== "" &&
      !data.appNo.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !data.forComRegion.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !data.email.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4);

    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }
    return { startPage, endPage };
  };
  const { startPage, endPage } = calculateRange(currentPage, totalPages);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  useEffect(() => {
    fetchB2bData();
  }, []);

  return (
    <>
      <Layout>
        <div className="d-flex flex-column justify-content-center align-items-center px-3">
          <div className="main-width d-flex flex-column">
            <hr />
            <DashboardHeader
              heading="Profile/ B2B Bookings"
              backUrl="/master-marketing-interest"
            />
            <hr />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex gap-3">
                <Form.Check
                  inline
                  label="All"
                  name="group1"
                  type="radio"
                  checked={reqStatus === ""}
                  onChange={() => setReqStatus("")}
                  defaultChecked
                />
                <Form.Check
                  inline
                  label="Processing"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Processing"}
                  onChange={() => setReqStatus("Processing")}
                />
                <Form.Check
                  inline
                  label="Approved"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Approved"}
                  onChange={() => setReqStatus("Approved")}
                />
                <Form.Check
                  inline
                  label="Rejected"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Rejected"}
                  onChange={() => setReqStatus("Rejected")}
                />
              </div>
              <input
                className="form-control w-25"
                type="search"
                placeholder="Search By company name/ company email"
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div>
              <Table responsive striped bordered hover>
                <thead>
                  <tr className="text-center">
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>Sr No.</th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>Date</th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>Company Details</th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>Interested Countries</th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>Booked Countries</th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>Overview</th>
                  </tr>
                </thead>

                {currentFiltered.length > 0 ? (
                  <>
                    {currentFiltered.map((data, index) => {
                      // Extract booked countries from slots
                      const bookedCountries = data.slots.map((slot) => slot.country);

                      return (
                        <tbody key={data._id} className="text-center">
                          <tr>
                            <td>{filteredData.length - indexOfFirstItem - index}.</td>

                            <td>
                              <div>
                                {data.appNoB2b}{" "}
                              <h6 className="text-success">
                                  {formatDateTimeStandard(data.updatedAt)}
                                </h6>
                              </div>
                            </td>

                            <td>
                              <div>
                                Company name: {data.companyName} <br />
                                Name: {data.name} <br />
                                Email: {data.email} <br />
                                Phone No: {data.phoneNo} <br />
                              </div>
                            </td>

                            {/* Interested Countries */}
                            <td>
                              <div>
                                {data.country.map((country, countryIndex) => (
                                  <Badge
                                    key={countryIndex}
                                    pill
                                    bg={bookedCountries.includes(country) ? 'success' : 'primary'}
                                    className="me-2 m-1 p-2"
                                  >
                                    {country}
                                  </Badge>
                                ))}
                              </div>
                            </td>

                            {/* Booked Slots */}
                            <td>
                              <div>
                                {data.slots.map((slot, slotIndex) => (
                                  <SlotDetailsModal key={slotIndex} slot={slot} data={data} />
                                ))}
                              </div>
                            </td>

                            <td>
                              <Overview data={data} />

                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={7} className="text-center">
                        No Matching Data
                      </td>
                    </tr>
                  </tbody>
                )}

              </Table>
            </div>
            {totalPages > 1 && (
              <div className="d-flex justify-content-start mt-2">
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  />
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <Pagination.Item
                        key={index + startPage}
                        active={index + startPage === currentPage}
                        onClick={() => handlePageChange(index + startPage)}
                      >
                        {index + startPage}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default B2bBookings;





const Overview = ({ data }) => {
  const [show, setShow] = useState(false);
  const [editableSlots, setEditableSlots] = useState(
    data.slots.map(slot => ({
      ...slot,
      isEditing: false,
      isComplimentary: false
    }))
  );
  const [editingRow, setEditingRow] = useState(null);
  const [selectedGST, setSelectedGST] = useState({
    igst: false,
    cgst: false,
    sgst: false,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleSlotTimeChange = (index, newValue) => {
    setEditableSlots(prevSlots => {
      const updatedSlots = [...prevSlots];
      updatedSlots[index].time = newValue;
      return updatedSlots;
    });
  };

  const handlePriceChange = (index, value) => {
    setEditableSlots(prevSlots => {
      const updatedSlots = [...prevSlots];
      const slot = updatedSlots[index];
      if (!slot.isComplimentary) {
        slot.price = Math.max(0, parseFloat(value) || 0);
      }
      return updatedSlots;
    });
  };

  const handleComplimentaryChange = (index) => {
    setEditableSlots(prevSlots => {
      const updatedSlots = [...prevSlots];
      const slot = updatedSlots[index];
      slot.isComplimentary = !slot.isComplimentary;

      // Reset price when changing to complimentary mode
      if (slot.isComplimentary) {
        slot.price = 0;
      } else {
        // Keep existing price when changing to non-complimentary mode
        slot.price = slot.price;
      }

      return updatedSlots;
    });
  };

  const handleSave = (index) => {
    setEditableSlots(prevSlots => {
      const updatedSlots = [...prevSlots];
      updatedSlots[index].isEditing = false;
      return updatedSlots;
    });
    setEditingRow(null);
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const timeSlots = [
    "10:00AM - 10:30AM",
    "10:45AM - 11:15AM",
    "11:30AM - 12:00PM",
    "12:15PM - 12:45PM",
    "01:00PM - 01:30PM",
    "02:30PM - 03:00PM",
    "03:15PM - 03:45PM",
    "04:00PM - 04:30PM",
    "04:45PM - 05:15PM",
    "05:30PM - 06:00PM",
  ];

  const calculateTotalAmount = () => {
    const baseTotal = editableSlots.reduce((total, slot) => total + Number(slot.price), 0);
    let gstAmount = 0;

    if (selectedGST.igst) {
      gstAmount += baseTotal * 0.18; // 18% for IGST
    } else {
      if (selectedGST.cgst) {
        gstAmount += baseTotal * 0.09; // 9% for CGST
      }
      if (selectedGST.sgst) {
        gstAmount += baseTotal * 0.09; // 9% for SGST
      }
    }

    return baseTotal + gstAmount; // Return total with GST
  };

  const handleGSTChange = (type) => {
    setSelectedGST((prev) => {
      const updatedGST = { ...prev, [type]: !prev[type] };
      return updatedGST;
    });
  };

  const getSelectedGSTTypes = () => {
    return Object.keys(selectedGST).filter((key) => selectedGST[key]);
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    editableSlots.forEach((slot, index) => {
      if (!slot.isComplimentary && slot.price <= 0) {
        errors[`slot${index}Price`] = 'Price must be greater than zero';
        isValid = false;
      }
    });

    setValidationErrors(errors);
    return isValid;
  };

  const handlePaymentRequest = async () => {
    if (!validateForm()) return;

    const finalAmount = calculateTotalAmount();
    const selectedGSTTypes = getSelectedGSTTypes();

    const preparedSlots = editableSlots.map(slot => ({
      country: slot.country,
      time: slot.time,
      price: slot.price,
      isComplimentary: slot.isComplimentary,
      gstType: selectedGSTTypes.join(', ')
    }));

    try {
      const res = await axios.post(getURLbyEndPointV2("sendPaymentReq") + data.clientId, {
        slots: preparedSlots,
        totalAmount: finalAmount,
        gstDetails: selectedGSTTypes,
      });

      setSubmitSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000); // Reload after 3 seconds
    } catch (error) {
      console.error("Error sending payment request:", error);
      // Alert('Failed to send payment request.');
    }
  };

  const totalAmount = calculateTotalAmount();

  return (
    <div>
      <Button variant="primary" size="sm" className="me-2" onClick={handleShow}>
        Booking Overview
      </Button>
      <Modal show={show} onHide={handleClose} centered size="xl" className="rounded h-100">
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h2 className="fw-bold">Booking Overview</h2>
            <CloseButton
              variant="danger"
              className="fs-5 me-4"
              onClick={handleClose}
            />
          </div>

          {/* Company and User Details */}
          <Row className="mb-4">
            <Col md={6}>
              <Card className="h-100">
                <Card.Body>
                  <h4 className="fw-bold mb-3">Company Details</h4>
                  <dl className="row">
                    <dt className="col-sm-4">Company Name:</dt>
                    <dd className="col-sm-8">{data.companyName}</dd>
                    <dt className="col-sm-4">Industry:</dt>
                    <dd className="col-sm-8">{data.industry}</dd>
                    <dt className="col-sm-4">CIN Number:</dt>
                    <dd className="col-sm-8">{data.cinNumber}</dd>
                    <dt className="col-sm-4">Entity:</dt>
                    <dd className="col-sm-8">{data.entity}</dd>
                  </dl>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="h-100">
                <Card.Body>
                  <h4 className="fw-bold mb-3">User Details</h4>
                  <dl className="row">
                    <dt className="col-sm-4">Name:</dt>
                    <dd className="col-sm-8">{data.name}</dd>
                    <dt className="col-sm-4">Email:</dt>
                    <dd className="col-sm-8">{data.email}</dd>
                    <dt className="col-sm-4">Phone:</dt>
                    <dd className="col-sm-8">{data.phoneNo}</dd>
                    <dt className="col-sm-4">Message:</dt>
                    <dd className="col-sm-8">{data.userMessage}</dd>
                  </dl>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Selected Countries and Slots */}
          <Card className="mb-4">
            <Card.Body>
              <h4 className="fw-bold mb-3">Selected Countries and Slot Times</h4>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>Country</th>
                    <th>Slot Time</th>
                    <th>Price</th>
                    {!data.isPaymentReq && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {editableSlots.map((slot, index) => (
                    <tr key={index}>
                      <td>{slot.country}</td>
                      <td>
                        {!data.isPaymentReq && editingRow === index ? (
                          <Form.Select
                            value={slot.time}
                            onChange={(e) => handleSlotTimeChange(index, e.target.value)}
                          >
                            {timeSlots.map((time, idx) => (
                              <option key={idx} value={time}>{time}</option>
                            ))}
                          </Form.Select>
                        ) : (
                          <span>{slot.time}</span>
                        )}
                      </td>
                      <td>
                        {!data.isPaymentReq && editingRow === index ? (
                          <div className="mb-2">
                            <Form.Control
                              type="number"
                              value={slot.price}
                              onChange={(e) => handlePriceChange(index, e.target.value)}
                              disabled={slot.isComplimentary}
                              isInvalid={validationErrors[`slot${index}Price`] !== undefined}
                            />
                            <Form.Control.Feedback type="invalid">
                              {validationErrors[`slot${index}Price`] || ''}
                            </Form.Control.Feedback>
                          </div>
                        ) : (
                          <>
                            {slot.price}
                            {slot.isComplimentary && ' (Complimentary)'}
                          </>
                        )}
                        <Form.Check
                          type="checkbox"
                          label="Complimentary"
                          checked={slot.isComplimentary}
                          onChange={() => handleComplimentaryChange(index)}
                        />
                      </td>
                      <td>
                        {!data.isPaymentReq && (
                          <div className="d-flex gap-2">
                            {editingRow === index ? (
                              <Button variant="success" onClick={() => handleSave(index)}>Save</Button>
                            ) : (
                              <OverlayTrigger
                                overlay={<Tooltip id={`edit-tooltip-${index}`}>Edit this row</Tooltip>}
                              >
                                <Button
                                  variant="primary"
                                  onClick={() => setEditingRow(index)}
                                >
                                  Edit
                                </Button>
                              </OverlayTrigger>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {/* Total Amount Display */}
              <div className="fw-bold mt-3">Total Amount: Rs. {totalAmount.toFixed(2)}/-</div>
            </Card.Body>
          </Card>


          {!data.isPaymentReq ? (
            <Card className="mb-4">
              <Card.Body>
                <h5 className="fw-bold mb-3">Add GST</h5>
                <div className="d-flex gap-4 mb-3">
                  <div>
                    <Form.Check
                      type="checkbox"
                      id="igst"
                      name="igst"
                      checked={selectedGST.igst}
                      onChange={() => handleGSTChange('igst')}
                      isInvalid={validationErrors.gst !== undefined}
                    />
                    <Form.Label htmlFor="igst">IGST</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {validationErrors.gst || ''}
                    </Form.Control.Feedback>
                  </div>
                  <div>
                    <Form.Check
                      type="checkbox"
                      id="cgst"
                      name="cgst"
                      checked={selectedGST.cgst}
                      onChange={() => handleGSTChange('cgst')}
                      isInvalid={validationErrors.gst !== undefined}
                    />
                    <Form.Label htmlFor="cgst">CGST</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {validationErrors.gst || ''}
                    </Form.Control.Feedback>
                  </div>
                  <div>
                    <Form.Check
                      type="checkbox"
                      id="sgst"
                      name="sgst"
                      checked={selectedGST.sgst}
                      onChange={() => handleGSTChange('sgst')}
                      isInvalid={validationErrors.gst !== undefined}
                    />
                    <Form.Label htmlFor="sgst">SGST</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {validationErrors.gst || ''}
                    </Form.Control.Feedback>
                  </div>
                </div>
                {submitSuccess && (
                  <Alert variant="success" className="mt-3">
                    Payment request sent successfully!
                  </Alert>
                )}
              </Card.Body>
            </Card>
          ) : (
            <Card className="mb-4">
              <Card.Body>
                <h5 className="fw-bold mb-3">Payment Status</h5>
                <p>
                    Payment status: {data.isPaymentApprove !== 'Processing' ? 'Completed': 'Processing'}
                </p>
                {data.paymentStatus === 'paid' && (
                  <p>
                    Paid amount: Rs. {data.paidAmount.toFixed(2)}
                  </p>
                )}
              </Card.Body>
            </Card>
          )}

          {/* Footer buttons */}
          <div className="d-flex justify-content-end gap-2 mt-4">
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            {!data.isPaymentReq && (
              <Button variant="primary" onClick={handlePaymentRequest}>
                Send Payment Request
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};










const SlotDetailsModal = ({ slot, data }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      {/* Button to trigger modal */}
      <Button size='sm' variant="outline-success" onClick={handleShowModal} className="country-button m-1">
        {slot.country}
      </Button>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">
            Slot Details for <strong>{slot.country}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Enhanced layout for details */}
          <div className="details-section">
            <p>
              <FaCalendarAlt className="me-2 text-success" /> <strong>Company name:</strong> {data.companyName}
            </p>
            <p>
              <FaCalendarAlt className="me-2 text-success" /> <strong>Date:</strong> {slot.date}
            </p>
            <p>
              <FaClock className="me-2 text-info" /> <strong>Time:</strong> {slot.time}
            </p>
            <p>
              <FaMoneyBill className="me-2 text-warning" /> <strong>Price:</strong> Rs. {slot.price}/-
            </p>
            <p>
              <FaClock className="me-2 text-secondary" /> <strong>Duration:</strong> {slot.duration}
            </p>
            <p>
              <FaCheckCircle className="me-2 text-primary" /> <strong>Booked At:</strong>{' '}
              {new Date(slot.bookedAt).toLocaleString()}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};




