import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
import Pagination from "react-bootstrap/Pagination";
import { Spinner } from "react-bootstrap";
import { PaymentConfirmModal } from "../../../components/basic/fileViewModal";

const Payment = () => {
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [accountStatus, setAccountStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;

  // Fetch payment details with pagination
  const fetchPaymentDetails = async (page = 1) => {
    setLoading(true); // Show loader when fetching data
    try {
      const response = await axios.get(getURLbyEndPoint("getAddFundRequests"), {
        params: {
          page,
          limit: itemsPerPage,
          searchTerm, 
        accountStatus, 
        },
      });

      if (response.status === 200) {
        setPaymentDetails(response.data.data);
        // Set total count and calculate total pages
        const totalCount = response.data.totalCount;
        setTotalPages(Math.ceil(totalCount / itemsPerPage));
      } else {
        console.error("Failed to fetch payment details.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Hide loader after fetching data
    }
  };

  const handleUpdateRejectAddFund = async (clientId, userId, status) => {
    console.log(clientId, userId, status);
    try {
      const response = await axios.put(
        getURLbyEndPoint("acceptRejectAddFund"),
        {
          clientId: clientId,
          userId: userId,
          status: status,
        }
      );
      console.log("Response:", response.data.message);
      alert(response.data.message);
      window.location.reload();
    } catch (error) {
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };

  // Filter data based on search term and account status
  const filteredData = paymentDetails.filter((item) => {
    if (accountStatus !== "" && item.status !== accountStatus) {
      return false;
    }

    const companyName = item?.companyName ? item.companyName.toLowerCase() : "";
    const remark = item?.remark ? item.remark.toLowerCase() : "";

    return (
      searchTerm === "" ||
      companyName.includes(searchTerm.toLowerCase()) ||
      remark.includes(searchTerm.toLowerCase())
    );
  });

  // Handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchPaymentDetails(pageNumber);
  };

  // Display a range of pagination items
  const paginationItems = () => {
    const items = [];
    const maxPaginationItems = 20;

    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxPaginationItems / 2)
    );
    const endPage = Math.min(totalPages, startPage + maxPaginationItems - 1);

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return items;
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      fetchPaymentDetails(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      fetchPaymentDetails(currentPage + 1);
    }
  };

  // Reset page to 1 when search term or account status changes
  useEffect(() => {
    setCurrentPage(1);
    fetchPaymentDetails(1);
  }, [searchTerm, accountStatus]);

  useEffect(() => {
    fetchPaymentDetails(currentPage);
  }, [currentPage]);

  return (
    <Layout>
      <div className="d-flex flex-column justify-content-center align-items-center px-3">
        <div className="main-width d-flex flex-column">
          <hr />
          <DashboardHeader
            heading="Services/ Payments"
            backUrl="/master-services"
          />
          <hr />
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex gap-3">
              <Form.Check
                inline
                label="All"
                name="group1"
                type="radio"
                checked={accountStatus === ""}
                onChange={() => setAccountStatus("")}
              />
              <Form.Check
                inline
                label="Processing"
                name="group1"
                type="radio"
                checked={accountStatus === "Processing"}
                onChange={() => setAccountStatus("Processing")}
              />
              <Form.Check
                inline
                label="Approved"
                name="group1"
                type="radio"
                checked={accountStatus === "Approved"}
                onChange={() => setAccountStatus("Approved")}
              />
              <Form.Check
                inline
                label="Rejected"
                name="group1"
                type="radio"
                checked={accountStatus === "Rejected"}
                onChange={() => setAccountStatus("Rejected")}
              />
            </div>
            <input
              className="form-control w-25"
              type="search"
              placeholder="Search Company Name/Remark"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {loading ? (
            <div className="d-flex justify-content-center mt-3">
              <Spinner animation="border" />
            </div>
          ) : (
            <Table responsive striped bordered hover className="text-center">
              <thead>
                <tr>
                  <th className="align-middle">SR NO.</th>
                  <th className="align-middle">User</th>
                  <th className="align-middle">Bank Details</th>
                  <th className="align-middle">Balance</th>
                  <th className="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((data, index) => (
                    <tr key={data._id}>
                      <td>
                        {index + 1 + (currentPage - 1) * itemsPerPage}
                        <div className="max-content">
                            Created at:
                            <br />
                            <span className="text-success">
                              {new Intl.DateTimeFormat("en-GB", {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                              }).format(new Date(data.createdAt))}
                            </span>
                          </div>
                      </td>
                      <td>
                          <div className="text-start max-content">
                            <h6>
                              Company Name :{" "}
                              <span className="text-secondary">
                                {" "}
                                {data.companyName}
                              </span>
                            </h6>
                            <h6>
                              Membership No. :{" "}
                              <span className="text-secondary">
                                {data.memberShipNo}
                              </span>
                            </h6>
                          </div>
                        </td>
                        <td>
                          <div className="text-start max-content">
                            <h6>
                              Bank Name:{" "}
                              <span className="text-secondary">
                                {data.registeredBank}
                              </span>
                            </h6>
                            <h6>
                              ACC. No.:{" "}
                              <span className="text-secondary">
                                {data.registeredAccountNo}
                              </span>
                            </h6>
                            <h6>
                              IFSC Code:{" "}
                              <span className="text-secondary">
                                {data.IFSCCode}
                              </span>
                            </h6>
                          </div>
                        </td>
                        <td>
                          <div className="text-start max-content">
                            <h6>
                              Balance :{" "}
                              <span className="text-secondary">
                                {data.balance}
                              </span>
                            </h6>
                            <h6>
                              Amount :{" "}
                              <span className="text-secondary">
                                {data.amount}
                              </span>
                            </h6>
                            <h6>
                              Type :{" "}
                              <span className="text-secondary">
                                {data.paymentType}
                              </span>
                            </h6>

                            {data.paymentScreenShot && (
                              <h6>
                                Utr No. :{" "}
                                <span className="text-secondary">
                                  {" "}
                                  {data.utrNo}
                                </span>
                              </h6>
                            )}
                            {data.paymentScreenShot && (
                              <a href={data.paymentScreenShot} target="_blank">
                                Payment ScreenShot
                              </a>
                            )}

                            {/* Add other address properties from data */}
                          </div>
                        </td>
                        <td>
                          <h6
                            className={
                              data.status === "Rejected"
                                ? "text-danger"
                                : "text-success"
                            }
                          >
                            {data.status}
                          </h6>

                          {data.status === "Processing" ? (
                            // <div className="d-flex justify-content-evenly mt-3">
                            //   <AiFillCheckCircle
                            //     className="icon-size green-color cursor-pointer"
                            //     onClick={() =>
                            //       handleUpdateRejectAddFund(
                            //         data.clientId,
                            //         data._id,
                            //         true
                            //       )
                            //     }
                            //   />
                            //   <AiFillCloseCircle
                            //     className="icon-size text-danger cursor-pointer"
                            //     onClick={() =>
                            //       handleUpdateRejectAddFund(
                            //         data.clientId,
                            //         data._id,
                            //         false
                            //       )
                            //     }
                            //   />
                            // </div>
                            <PaymentConfirmModal
                            handleUpdateRejectAddFund={
                              handleUpdateRejectAddFund
                            }
                            clientId={ data.clientId}
                            userId={data._id}
                          />
                          ) : (
                            <div className="fw-bold text-center">
                              <span
                                className={
                                  data.status === "Rejected"
                                    ? "text-danger"
                                    : "text-success"
                                }
                              >
                                {new Intl.DateTimeFormat("en-GB", {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                }).format(new Date(data.updatedAt))}
                              </span>
                            </div>
                          )}

                          {data.remark && <span>{data.remark}</span>}
                        </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                     <td colSpan={12} className="text-center">
                      No Matching Data
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
          {totalPages > 1 && (
            <div className="d-flex justify-content-start mt-2">
              <Pagination>
                <Pagination.First
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                />
                <Pagination.Prev
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                />
                {paginationItems()}
                <Pagination.Next
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                />
                <Pagination.Last
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Payment;






